import { useMutation } from "@tanstack/react-query";
import { pushNotificationService } from "../services/push-notifications.service";

export const useSendPushNotification = () => {
  const {
    mutate: sendPush,
    isPending: isLoading,
    isError,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: pushNotificationService.sendPushNotification,
  });

  return {
    sendPush,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};
