import { axios } from "../utils/axios.create";

interface PushNotificationParams {
  deviceToken: string;
  message: string;
  subject?: string;
  screen?: string;
  params?: Record<string, unknown>;
}

const sendPushNotification = async ({
  deviceToken,
  message,
  subject,
  screen,
  params,
}: PushNotificationParams): Promise<void> => {
  const url = `/alerts/push/template/${deviceToken}`;
  return axios
    .post(url, {
      subject,
      message,
      feature: "communication-chime",
      product: "PARCA",
      screen,
      params,
    })
    .then((response) => {
      console.log("Push notification sent successfully:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error sending push notification:", error.response?.data);
      return Promise.reject(error.response?.data || error.message);
    });
};

export const pushNotificationService = {
  sendPushNotification,
};
